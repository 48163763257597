'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'far';
var iconName = 'prescription-bottle';
var width = 384;
var height = 512;
var aliases = [];
var unicode = 'f485';
var svgPathData = 'M48 48l288 0 0 32L48 80l0-32zM40 0C17.9 0 0 17.9 0 40L0 88c0 22.1 17.9 40 40 40l304 0c22.1 0 40-17.9 40-40l0-48c0-22.1-17.9-40-40-40L40 0zM32 160l0 288c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-288-48 0 0 288c0 8.8-7.2 16-16 16L96 464c-8.8 0-16-7.2-16-16l0-32 56 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-56 0 0-48 56 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-56 0 0-48 56 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-56 0 0-16-48 0z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faPrescriptionBottle = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;